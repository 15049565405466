/* Global variables */

:root {
  --text-color: hsl(0, 0%, 0%);
  --active-color: hsl(195, 55%, 80%);
  --active-color-light: hsl(195, 55%, 85%);
  --active-color-dark: hsl(195, 55%, 75%);
  --select-color: hsl(280, 55%, 70%);
  --select-color-light: hsl(280, 55%, 75%);
  --select-color-dark: hsl(280, 55%, 65%);
  --inactive-color: hsl(195, 25%, 95%);
  --error-color: hsl(350, 85%, 60%);
  --warning-color: hsl(50, 100%, 50%);
  --success-color: hsl(120, 60%, 50%);
}

/* Individual elements */

dl {
  display: grid;
  grid-template-columns: auto 1fr;
}

dl dl {
  margin: auto;
}

.table-container {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 1rem;
}

td,
th {
  padding: 0.5rem;
  text-align: center;
}

li {
  margin-bottom: 0;
}

.hidden {
  display: none;
}

.error {
  border-color: hsl(350, 85%, 60%);
  border-color: var(--error-color);
}

ul.errors,
ul.parsley-errors-list {
  list-style: none;
  color: hsl(350, 85%, 60%);
  color: var(--error-color);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  font-size: unset;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  line-height: unset;
  margin-bottom: 0;
}

.button {
  margin-bottom: 1.5rem;
}

.miller-negative {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.miller-negative-digit {
  text-decoration: overline;
}

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  border-radius: 0;
}

/* Milligram's select styling is no longer supported in latest Firefox/Chrome, due to unescaped # character.
 * Been fixed according to https://github.com/milligram/milligram/issues/161, but not released */
select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="%23d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
}

select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="%239b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
}

/* Global structure */

body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

main,
header,
footer {
  width: 90%;
  margin: 2rem auto;
}

main {
  flex-grow: 1;
  flex-shrink: 0;
}

footer {
  flex-shrink: 0;
}

/* Structure */

/** Top menu **/

.menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 1px;
}

.menu-item {
  flex-grow: 1;
  list-style: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  box-shadow: 0 0 0 1px hsl(0, 0%, 0%);
  box-shadow: 0 0 0 1px var(--text-color);
  text-align: center;
  background-color: hsl(195, 25%, 95%);
  background-color: var(--inactive-color);
  border: 4px solid transparent;
}

.menu-link {
  display: block;
  color: hsl(0, 0%, 0%);
  color: var(--text-color);
}

.menu-item:hover {
  background-color: hsl(280, 55%, 70%);
  background-color: var(--select-color);
  border-top-color: hsl(280, 55%, 75%);
  border-top-color: var(--select-color-light);
  border-right-color: hsl(280, 55%, 75%);
  border-right-color: var(--select-color-light);
  border-bottom-color: hsl(280, 55%, 65%);
  border-bottom-color: var(--select-color-dark);
  border-left-color: hsl(280, 55%, 65%);
  border-left-color: var(--select-color-dark);
}

.menu-link:hover {
  color: hsl(0, 0%, 0%);
  color: var(--text-color);
}

.menu-item.active {
  font-weight: bold;
  background-color: hsl(195, 55%, 80%);
  background-color: var(--active-color);
  border-top-color: hsl(195, 55%, 85%);
  border-top-color: var(--active-color-light);
  border-right-color: hsl(195, 55%, 85%);
  border-right-color: var(--active-color-light);
  border-bottom-color: hsl(195, 55%, 75%);
  border-bottom-color: var(--active-color-dark);
  border-left-color: hsl(195, 55%, 75%);
  border-left-color: var(--active-color-dark);
}

/* IE doesn't support @supports, but it also doesn't support grid properly */
/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports (display: grid) {
  .menu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

/** Messages **/

.messages {
  /* Not supported in Opera Mini, should display okay without */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.messages ul {
  margin: 0;
}

.message {
  list-style: none;
  border: 1px solid hsl(0, 0%, 0%);
  border: 1px solid var(--text-color);
  text-align: center;
  font-weight: bold;
  background-color: hsl(195, 55%, 80%);
  background-color: var(--active-color);
  margin-top: 1rem;
  padding: 1rem;
  color: hsl(0, 0%, 0%);
  color: var(--text-color);

  /* Not supported in Opera Mini, should display okay without */
  /* stylelint-disable plugin/no-unsupported-browser-features */
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: disappear;
          animation-name: disappear;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /* stylelint-enable */
}

@-webkit-keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.message-success {
  background-color: hsl(120, 60%, 50%);
  background-color: var(--success-color);
}

.message-warning {
  background-color: hsl(50, 100%, 50%);
  background-color: var(--warning-color);
}

.message-error {
  background-color: hsl(350, 85%, 60%);
  background-color: var(--error-color);
}

/* stylelint-disable plugin/no-unsupported-browser-features */
@supports (transform: translateX(-50%)) {
  .messages {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .message {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
/* stylelint-enable */

/** Footer **/

.partners {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.partners a {
  flex-grow: 1;
  margin: 1rem;
  text-align: center;
}

.partners a img {
  height: 7rem;

  /* Only unsupported on IE / older Edge, and we only use it to prevent issues with very small screens */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -o-object-fit: contain;
     object-fit: contain;
}

.legal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/** Pagination **/

.pagination ul {
  display: flex;
  justify-content: end;
  padding: 0;
}

.pagination-page-info {
  text-align: right;
  padding: 0;
}

.pagination li {
  flex-shrink: 0;
  min-width: 3rem;
  list-style: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  box-shadow: 0 0 0 1px hsl(0, 0%, 0%);
  box-shadow: 0 0 0 1px var(--text-color);
  border: 2px solid transparent;
  text-align: center;
  background-color: hsl(195, 25%, 95%);
  background-color: var(--inactive-color);
}

.pagination li a {
  display: block;
  color: hsl(0, 0%, 0%);
  color: var(--text-color);
}

.pagination li:hover {
  background-color: hsl(280, 55%, 70%);
  background-color: var(--select-color);
  border-top-color: hsl(280, 55%, 75%);
  border-top-color: var(--select-color-light);
  border-right-color: hsl(280, 55%, 75%);
  border-right-color: var(--select-color-light);
  border-bottom-color: hsl(280, 55%, 65%);
  border-bottom-color: var(--select-color-dark);
  border-left-color: hsl(280, 55%, 65%);
  border-left-color: var(--select-color-dark);
}

.pagination li:hover a {
  color: hsl(0, 0%, 0%);
  color: var(--text-color);
}

.pagination li.active {
  font-weight: bold;
  background-color: hsl(195, 55%, 80%);
  background-color: var(--active-color);
  border-top-color: hsl(195, 55%, 85%);
  border-top-color: var(--active-color-light);
  border-right-color: hsl(195, 55%, 85%);
  border-right-color: var(--active-color-light);
  border-bottom-color: hsl(195, 55%, 75%);
  border-bottom-color: var(--active-color-dark);
  border-left-color: hsl(195, 55%, 75%);
  border-left-color: var(--active-color-dark);
}

.pagination li.disabled {
  display: block;
  background-color: white;
}

.pagination li.disabled a {
  color: hsl(195, 25%, 95%);
  color: var(--inactive-color);
}

/** Tabs **/

.tabs summary {
  display: block;
  text-align: center;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  box-shadow: 0 0 0 1px hsl(0, 0%, 0%);
  box-shadow: 0 0 0 1px var(--text-color);
  border: 4px solid;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: hsl(195, 55%, 80%);
  background-color: var(--active-color);
  border-top-color: hsl(195, 55%, 85%);
  border-top-color: var(--active-color-light);
  border-right-color: hsl(195, 55%, 85%);
  border-right-color: var(--active-color-light);
  border-bottom-color: hsl(195, 55%, 75%);
  border-bottom-color: var(--active-color-dark);
  border-left-color: hsl(195, 55%, 75%);
  border-left-color: var(--active-color-dark);
}

.tabs h3 {
  font-size: 1.6rem;
  margin: 0;
  display: inline;
  font-weight: bold;
}

/* @supports doesn't work on IE, but neither does details */
/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports (display: list-item) {
  .tabs summary {
    cursor: pointer;
    background-color: hsl(195, 25%, 95%);
    background-color: var(--inactive-color);
    border-color: transparent;
    display: list-item;
    margin-bottom: 0;
  }

  .tabs h3 {
    font-weight: normal;
  }

  .tabs summary:hover {
    background-color: hsl(280, 55%, 70%);
    background-color: var(--select-color);
    border-top-color: hsl(280, 55%, 75%);
    border-top-color: var(--select-color-light);
    border-right-color: hsl(280, 55%, 75%);
    border-right-color: var(--select-color-light);
    border-bottom-color: hsl(280, 55%, 65%);
    border-bottom-color: var(--select-color-dark);
    border-left-color: hsl(280, 55%, 65%);
    border-left-color: var(--select-color-dark);
  }

  .tabs details[open] summary {
    font-weight: bold;
    background-color: hsl(195, 55%, 80%);
    background-color: var(--active-color);
    border-top-color: hsl(195, 55%, 85%);
    border-top-color: var(--active-color-light);
    border-right-color: hsl(195, 55%, 85%);
    border-right-color: var(--active-color-light);
    border-bottom-color: hsl(195, 55%, 75%);
    border-bottom-color: var(--active-color-dark);
    border-left-color: hsl(195, 55%, 75%);
    border-left-color: var(--active-color-dark);
    margin-bottom: 1rem;
  }
}

/** Results tables **/

.thumbnail {
  height: 12rem;
  width: 12rem;
}

/** Details **/

.view-details {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/** Jmol **/

.jmol-container,
.jmol-fallback {
  /* Only unsupported on Opera Mini, and even on that browser, looks okay */
  /* stylelint-disable plugin/no-unsupported-browser-features */
  height: 1000px;
  height: 90vh;
  width: 1000px;
  width: 90vh;
  max-height: 90vw;
  max-width: 90vw;
  /* stylelint-enable */
}

.jmol-fallback {
  position: absolute;
}

.jmol-actions {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.jmol-actions .input-container {
  flex-basis: 18rem;
  display: flex;
  justify-content: space-between;
}

.jmol-actions select {
  width: auto;
}

/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports (display: grid) {
  .jmol-actions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }

  .jmol-actions input[type=number] {
    width: 8rem;
  }

  .jmol-actions .input-container {
    display: grid;
    grid-template-areas:
      "label input"
      "error error";
  }

  .jmol-actions .input-container label {
    grid-area: label;
  }

  .jmol-actions .input-container input {
    grid-area: input;
  }

  .jmol-actions .input-container input[type=button] {
    grid-column-start: label;
    grid-column-end: input;
  }

  .jmol-actions .input-container .error-container {
    grid-area: error;
  }
}

.jmol-actions .hidden {
  display: none;
}

.jmol-frame {
  border: 0;

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  max-width: 90vw;
  width: 700px;
}

.inner-iframe .jmol-container,
.inner-iframe .jmol-fallback {
  height: 700px;
  width: 700px;
}

.inner-iframe .jmol-actions {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  max-width: 90vw;
  width: 700px;
}

.jmol-maximise {
  display: block;
  text-align: right;
  width: 700px;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  max-width: 90vw;
}

@media screen and (max-width: 699px) {
  .jmol-maximise {
    display: none;
  }
}

/** Actions/Filters **/

ul.form-grid,
.form-grid ul {
  list-style: none;
}

.form-grid .input-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
}

.form-grid fieldset {
  border: 1px solid hsl(195, 25%, 95%);
  border: 1px solid var(--inactive-color);
  padding: 1rem;
}

.form-grid .fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.form-grid .fieldset .input-container {
  flex-grow: 1;
  flex-basis: 8rem;
  margin: auto 1rem;
  min-width: 8rem;
  height: 100%;
}

.form-grid .help {
  align-self: end;
  font-size: smaller;
}

/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports (display: grid) {
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    align-items: start;
  }

  .form-grid fieldset {
    grid-column: 1 / -1;
  }

  .form-grid .roles-roles-input-container {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .form-grid .roles-roles-input-container label {
    display: inline;
  }

  .form-grid .button-container {
    align-self: end;
    justify-content: end;
  }

  @media screen and (min-width: 48rem) {
    .form-grid .miller_index-fieldset,
    .form-grid .upload_file-input-container {
      grid-column: auto / span 2;
    }

    .form-grid .status-fieldset {
      grid-column: auto / span 3;
    }
  }

  @media screen and (min-width: 76rem) {
    .form-grid fieldset {
      grid-column: auto / span 3;
    }
  }
}

.help-detail {
  padding-left: 1rem;
  padding-right: 1rem;
}

.help-detail:target {
  background-color: hsl(195, 55%, 80%);
  background-color: var(--active-color);
}

.help-detail a img {
  display: block;
  margin: auto;
  max-height: 30rem;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -o-object-fit: contain;
     object-fit: contain;
}
